import React from 'react';

export const CountdownClock = ({ days, hours, minutes, seconds }) => (
    <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div style={{ margin: '5px' }}>{days}</div>
        <div style={{ margin: '5px' }}>{hours}</div>
        <div style={{ margin: '5px' }}>{minutes}</div>
        <div style={{ margin: '5px' }}>{seconds}</div>
    </div>
);
